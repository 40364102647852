
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  

  
  var importedApp = {};


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://c098db2425c540d3982e073ae7fc4c9a@sentry.wixpress.com/3531',
      id: 'c098db2425c540d3982e073ae7fc4c9a',
      projectName: 'comments-widget',
      teamName: 'forum',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":["wix-comments"],"centralized":true};

  var translationsConfig = {"enabled":true,"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/c2e2b59e62116bb/comments-web/comments-widget/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var defaultTranslations = {"gfpp.manage-comments":"Manage Comments","editor-nofication.category-restored-success":"Comments restored.","progress-bar.title":"Adding Comments widget...","error-panel.title":"Couldn't add the widget","error-panel.description":"The widget couldn't be added due to a technical issue. Refresh the page and try adding it again.","error-panel.main-button":"Close"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/c2e2b59e62116bb/comments-web/comments-widget/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "comments-widget",
    biConfig: {"enableUniversalEvents":true},
    appName: "Wix Comments",
    appDefinitionId: "91c9d6a7-6667-41fb-b0b4-7d3b3ff0b02e",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["wix-comments"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: {"enableUniversalEvents":true},
          controllerFileName: "/home/builduser/work/c2e2b59e62116bb/comments-web/comments-widget/src/components/CommentsWidget/controller.ts",
          appName: "Wix Comments",
          appDefinitionId: "91c9d6a7-6667-41fb-b0b4-7d3b3ff0b02e",
          projectName: "comments-widget",
          componentName: "CommentsWidget",
          localeDistPath: "components/CommentsWidget/locales/widget",
          defaultTranslations: {"markLabel.bestAnswer.label":"Best Answer","markLabel.bestAnswer.markAction":"Mark as Best Answer","markLabel.bestAnswer.unmarkAction":"Unmark as Best Answer","markLabel.solved.label":"Solved","markLabel.solved.markAction":"Mark as Solved","markLabel.solved.unmarkAction":"Unmark as Solved","markLabel.featured.label":"Featured","markLabel.featured.markAction":"Mark as Featured","markLabel.featured.unmarkAction":"Unmark as Featured","markLabel.pinned.label":"Pinned","markLabel.pinned.markAction":"Mark as Pinned","markLabel.pinned.unmarkAction":"Unmark as Pinned","markLabel.custom.markAction":"Mark as {customLabel}","markLabel.custom.unmarkAction":"Unmark as {customLabel}","demoContent.comment01":"Your site visitors can leave comments like this one to create a conversation around your site’s content. Some can be just plain text and some can contain images, gifs, videos and other embedded content.","demoContent.comment02":"Comments can be as short or as long as your visitors would like.","demoContent.reply01":"Other visitors can also reply to comments.","demoContent.reply02":"There are even replies to a reply.","demoContent.replierName":"Replier Name","demoContent.commenterName":"Commenter Name","demoContent.alert":"These are demo comments. They will not be shown live when you publish your site.","settings.textTab.headerTitleTextDefault":"Comments","settings.textTab.headerTitleText":"{count, plural, =0 {{title}} other {{title} ({count})}}","settings.textTab.placeholderTextDefault":"Write a comment","settings.textTab.publishButtonTextDefault":"Publish","settings.textTab.likeButtonTextDefault":"Like","settings.textTab.replyButtonTextDefault":"Reply","settings.settingsTab.ratingSelectorTextDefault":"Add a rating","settings.textTab.adminBadgeTextDefault":"Admin"},
          id: "1c154801-444a-49ad-aee8-325ff74f89b1" }],
    false);

    export const createControllers = _createControllers
